<template>
  <sheet
    v-if="!disabled"
    v-model:visible="model"
    :only-header-swipe="onlyHeaderSwipe"
    v-bind="$attrs"
  >
    <template #header>
      <div class="touch-bar">
        <span></span>
      </div>
      <slot name="header"></slot>
    </template>
    <slot></slot>
  </sheet>
  <template v-else>
    <slot name="header"></slot>
    <slot></slot>
  </template>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue'
import { Sheet } from 'bottom-sheet-vue3'
import {
  disableDocumentScroll,
  enableDocumentScroll,
  ModelMixin,
  useModel,
} from 'wue'
import { useViewTools } from '~/composables'
import { chatToBackground, chatToDefault } from '~/utils'

export default defineComponent({
  name: 'BottomSheet',
  components: { Sheet },
  mixins: [ModelMixin],
  inheritAttrs: false,
  props: {
    onlyHeaderSwipe: { type: Boolean, default: () => false },
  },
  setup(props, ctx) {
    const modelCtx = useModel<boolean>(props as any, ctx, {
      initialValue: false,
    })
    const viewTools = useViewTools()
    const disabled = computed(() => !viewTools.isTablet.value)

    watch(
      () => modelCtx.model.value,
      (value) => {
        if (!disabled.value) {
          if (value) {
            disableDocumentScroll()
            chatToBackground()
          } else {
            enableDocumentScroll()
            chatToDefault()
          }
        }
      }
    )

    return {
      disabled,
      ...modelCtx,
    }
  },
})
</script>

<style scoped lang="scss">
.touch-bar {
  span {
    display: block;
    height: 5px;
    width: 60px;
    background-color: var(--hb-gray2);
    margin: 10px auto 0;
    border-radius: 10px;
  }
}
</style>
